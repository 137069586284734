import { graphql, useStaticQuery } from 'gatsby';

interface UseSiteMetadataProps {
  siteUrl?: string;
  companyName?: string;
  shortName?: string;
  googleVerification?: string;
  pinterestVerification?: string;
  metaTitleFallback?: string;
  twitter?: string;
  footerTerms?: string;
  contactNumber?: string;
  trustpilotUrl?: string;
  isFreeJourney?: boolean;
  subSpecies?: string;
}

export const useSiteMetadata = (): UseSiteMetadataProps => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query getSiteMetadata {
      site {
        siteMetadata {
          siteUrl
          companyName
          shortName
          googleVerification
          pinterestVerification
          metaTitleFallback
          twitter
          footerTerms
          contactNumber
          trustpilotUrl
          isFreeJourney
          affiliateType
          subSpecies
        }
      }
    }
  `);

  return siteMetadata;
};
