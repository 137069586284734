import React, {
  createContext,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import {
  getCookie,
  getQueryString,
  isGUID,
  setCookie,
  useSyncContextToSessionStorage,
} from '@agria/utils';
import type { PageProps } from 'gatsby';
import {
  AuthorityToDeal,
  Basket,
  Customer,
  Pet,
  Quote,
  EquineQuote,
  AffiliateBreederClubEnrolmentRequest,
  PetEquine,
  Benefit,
} from '@agria/paws/src/types';
import { useGetBasket } from '@agria/paws/src/hooks/useGetBasket';
import { PawsBrand, useBrand } from '@agria/paws/src/hooks/useBrand';
import { useCampaignId } from '@agria/paws/src/hooks/useCampaignId';
import { useAffiliateLookup } from '@agria/paws/src/hooks/useAffiliateLookup';
import * as Sentry from '@sentry/gatsby';
import { useSiteMetadata } from '@agria/theme/src/hooks/useSiteMetadata';
import useGetDataLayerContentType from '../hooks/useGetDataLayerContentType';
import { subSpeciesIds } from '../const/subSpeciesIds';

import { useDefaultPromoCode } from '../hooks/useDefaultPromoCode';

export interface BreederPolicyCustomer extends Partial<Customer> {
  AdditionalTitleId?: string;
  AdditionalFirstName?: string;
  AdditionalLastName?: string;
}
export interface BreederPolicyRequest {
  Pet: Partial<Pet>;
  Customer?: BreederPolicyCustomer;
  CustomerId?: number;
  id: number;
}
interface QuoteContextProviderProps {
  children: React.ReactNode;
  location: PageProps['location'];
}

interface CampaignContextState {
  campaignId: string | null;
  affiliateId: string | null;
  partnerTrackingId: string | null;
  sskey: string | null;
  utmSource: string | null;
}

export interface RequestedQuoteItem {
  quoteIds?: string[]; // array of quote IDs
  count: number;
}

export interface AffiliateResponse {
  AgriaId: string;
  EmailAddress: string;
  PostCode: string;
}
// interface QuoteProgress {
//   currentStep: string; // TODO Type This! or do we need it now with Active Step Context?
//   requestedQuotes: Record<string, RequestedQuote>;
// }
// interface ActiveSpecies {
//   name: AllowedSpecies;
//   id: string;
// }
export type RequestedQuotes = Record<string, RequestedQuoteItem>;
interface QuoteContextState {
  campaign: CampaignContextState; // Marketing information
  activeQuote: Quote | EquineQuote | null; // Currently Active Quote, may not be in basket yet
  activeSpecies?: string | null; // Currently Active species to quote on
  basket: Basket | null; // Complete basket returned from GetBasket
  basketId: string | null; //  basket ID returned from GetBasket
  requestedQuotes: RequestedQuotes | null; // Tracks progress through journey
  brand: PawsBrand | null; // Active Brand e.g Agria / KC
  additionalPolicyHolder: Partial<AuthorityToDeal> | null;
  fetchBasket: () => void;
  basketLoading?: boolean;
  basketError?: any;
  policyIds?: string[];
  isContinueCover: boolean;
  extraData?: Record<string, any>;
  affiliatePolicyRequests?: Partial<BreederPolicyRequest>[];
  affiliateEnrolment:
    | Partial<AffiliateBreederClubEnrolmentRequest>
    | Record<string, any>
    | null;
  affiliateResponse?: AffiliateResponse | null;
  dataLayerContentType?: string;
  dataLayerProductOptions?: Benefit | null;
  hasSet?: boolean;
  editMode?: string | number | null; // quote id (can be number on affliate)
}

interface QuoteContextStateReturn extends QuoteContextState {
  dispatch: React.Dispatch<Action>;
  totalQuoteRequests: number;
  totalCompletedQuoteRequests: number;
  allActiveSpeciesCount: number;
  refetchBasket: () => void;
  hasBasket: boolean;
  activeSpeciesCount: number;
  activeSpeciesTotalCount: number;
  promoCode?: string;
}
export interface QueryContextQueryStrings {
  camid?: string;
  affid?: string;
  sskey?: string;
  utm_source?: string;
  quoterefGuid?: string;
  promocode?: string;
  pc?: string;
  basketId?: string;
  QuoteRef?: string;
  Postcode?: string;
}

const initialCampaignState: CampaignContextState = {
  campaignId: null,
  affiliateId: null,
  partnerTrackingId: null,
  sskey: null,
  utmSource: null,
};

const initialState: QuoteContextState = {
  campaign: initialCampaignState,
  activeQuote: null,
  activeSpecies: null,
  basket: null,
  basketId: null,
  basketError: null,
  requestedQuotes: null,
  brand: null,
  additionalPolicyHolder: null,
  fetchBasket: () => console.warn('fetchBasket not implemented'),
  policyIds: [],
  isContinueCover: false,
  affiliatePolicyRequests: [],
  affiliateEnrolment: null,
  affiliateResponse: null,
  basketLoading: false,
  dataLayerProductOptions: null,
  promoCode: '',
};

type Action =
  | {
      type: 'SET_ALL';
      payload: any | null;
    }
  | {
      type: 'COMPLETE_PURCHASE';
      payload?: null;
    }
  | {
      type: 'RESET';
      payload?: null;
    }
  | {
      type: 'SET_CONTINUE_COVER';
      payload: boolean;
    }
  | {
      type: 'SET_EXTRA_DATA';
      payload: Record<string, any>;
    }
  | {
      type: 'SET_PROMO_CODE';
      payload: string;
    }
  | {
      type: 'REMOVE_PROMO_CODE';
      payload?: null;
    }
  | {
      type: 'SET_TERMS_AGREED';
      payload: boolean;
    }
  | {
      type: 'SET_HAS_SET';
      payload: boolean;
    }
  | {
      type: 'SET_CAMPAIGN_ID';
      payload: string | null;
    }
  | {
      type: 'SET_PARTNER_ID';
      payload: string | null;
    }
  | {
      type: 'SET_BASKET_ID';
      payload: string | null;
    }
  | {
      type: 'SET_AFFILIATE_ID';
      payload: string | null;
    }
  | {
      type: 'SET_AFFILIATE_ENROLMENT';
      payload: Partial<AffiliateBreederClubEnrolmentRequest> | null;
    }
  | {
      type: 'SET_AFFILIATE_RESPONSE';
      payload: AffiliateResponse | Record<string, any> | null;
    }
  | {
      type: 'SET_BRAND';
      payload: PawsBrand;
    }
  | {
      type: 'SET_SSKEY';
      payload: string | null;
    }
  | {
      type: 'SET_UTM_SOURCE';
      payload: string | null;
    }
  | {
      type: 'SET_QUOTE_REQUESTS';
      payload: RequestedQuotes | null;
    }
  | {
      type: 'CANCEL_OUTSTANDING_QUOTE_REQUESTS';
    }
  | {
      type: 'SET_ACTIVE_PET';
      payload: Partial<Pet | PetEquine> | null;
    }
  | {
      type: 'SET_ACTIVE_CUSTOMER';
      payload: Partial<Customer> | null;
    }
  | {
      type: 'SET_ACTIVE_QUOTE';
      payload: Partial<Quote | EquineQuote> | null;
    }
  | {
      type: 'SET_ACTIVE_QUOTE_BY_ID';
      payload: string;
    }
  | {
      type: 'SET_BASKET';
      payload: Basket | null;
    }
  | {
      type: 'SET_POLICES';
      payload: string[];
    }
  | {
      type: 'SET_ADDITIONAL_POLICY_HOLDER';
      payload: Partial<AuthorityToDeal> | null;
    }
  | {
      type: 'REMOVE_ADDITIONAL_POLICY_HOLDER';
    }
  | {
      type: 'SET_DATALAYER_CONTENT_TYPE';
      payload: string;
    }
  | {
      type: 'SET_DATALAYER_PRODUCT_OPTIONS';
      payload: Benefit;
    }
  | {
      type: 'SET_EDIT_MODE';
      payload: string | number | null;
    }
  | {
      type: 'SET_BREEDER_POLICY_REQUEST';
      payload: Partial<BreederPolicyRequest>;
    }
  | {
      type: 'SET_BREEDER_POLICY_OWNER';
      payload: {
        customer: Partial<Customer>;
        id: number;
      };
    }
  | {
      type: 'SET_BREEDER_POLICY_PREVIOUS_OWNER';
      payload: {
        customerId: number;
        id: number;
      };
    };

const reducer = (state: QuoteContextState, action: Action) => {
  console.debug('Action.type', action.type);
  // console.debug(action?.payload);
  switch (action.type) {
    case 'SET_ALL':
      return {
        ...action.payload,
      };
    case 'COMPLETE_PURCHASE': {
      const prev = { ...state };
      prev.requestedQuotes = null;
      prev.additionalPolicyHolder = null;
      prev.editMode = null;
      // prev.activeQuote = null;
      return {
        ...prev,
      };
    }
    case 'RESET': {
      const prev = { ...state };
      prev.requestedQuotes = null;
      prev.additionalPolicyHolder = null;
      prev.activeSpecies = null;
      prev.activeQuote = null;
      prev.extraData = undefined;
      prev.basketId = null;
      prev.basket = null;
      prev.isContinueCover = false;
      prev.editMode = null;
      return {
        ...prev,
      };
    }
    case 'SET_CONTINUE_COVER':
      return {
        ...state,
        isContinueCover: action.payload,
      };
    case 'SET_BREEDER_POLICY_REQUEST': {
      // if id in payload.id already exists in the affiliatePolicyRequests array, replace it else add it
      const affiliatePolicyRequests = [
        ...(state.affiliatePolicyRequests ?? []),
      ];
      const index = affiliatePolicyRequests.findIndex(
        (item) => item.id === action.payload.id
      );

      if (index !== -1) {
        affiliatePolicyRequests[index] = action.payload;
      } else {
        affiliatePolicyRequests.push(action.payload);

        // get the Pet.SpeciesId and then add the id to the requestedQuotes quoteIds array
        const speciesId = action.payload.Pet?.SpeciesId;
        if (speciesId) {
          const { requestedQuotes } = state;
          if (requestedQuotes && action.payload.id) {
            requestedQuotes[speciesId]?.quoteIds?.push(`${action.payload.id}`);
          }
        }
      }

      return {
        ...state,
        affiliatePolicyRequests,
      };
    }
    case 'SET_AFFILIATE_RESPONSE':
      return {
        ...state,
        affiliateResponse: action.payload || null,
      };
    case 'SET_DATALAYER_PRODUCT_OPTIONS':
      return {
        ...state,
        dataLayerProductOptions: action.payload || null,
      };
    case 'SET_BREEDER_POLICY_OWNER': {
      const affiliatePolicyRequests = [
        ...(state.affiliatePolicyRequests ?? []),
      ];
      const index = affiliatePolicyRequests.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        affiliatePolicyRequests[index].Customer = action.payload.customer;
        delete affiliatePolicyRequests[index].CustomerId;
      }
      return {
        ...state,
        affiliatePolicyRequests,
      };
    }
    case 'SET_BREEDER_POLICY_PREVIOUS_OWNER': {
      const affiliatePolicyRequests = [
        ...(state.affiliatePolicyRequests ?? []),
      ];
      const index = affiliatePolicyRequests.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        affiliatePolicyRequests[index].CustomerId = action.payload.customerId;
        delete affiliatePolicyRequests[index].Customer;
      }
      return {
        ...state,
        affiliatePolicyRequests,
      };
    }
    case 'SET_PROMO_CODE':
      return {
        ...state,
        promoCode: action.payload,
        activeQuote: {
          ...state.activeQuote,
          PromoCode: action.payload,
        },
      };
    case 'REMOVE_PROMO_CODE':
      return {
        ...state,
        promoCode: '',
        activeQuote: {
          ...state.activeQuote,
          PromoCode: '',
        },
      };
    case 'SET_TERMS_AGREED':
      return {
        ...state,
        activeQuote: {
          ...state.activeQuote,
          TermsAgreed: action.payload,
        },
      };

    case 'SET_HAS_SET':
      return {
        ...state,
        hasSet: action.payload,
      };

    case 'SET_AFFILIATE_ENROLMENT':
      return {
        ...state,
        affiliateEnrolment: action.payload,
      };
    case 'SET_BASKET_ID':
      return {
        ...state,
        basketId: action.payload ?? null,
      };
    case 'SET_CAMPAIGN_ID':
      return {
        ...state,

        activeQuote:
          state.activeQuote && action.payload
            ? {
                ...state.activeQuote,
                CampaignId: action.payload ?? null,
              }
            : null,
        campaign: {
          ...state.campaign,
          campaignId: action.payload ?? null,
        },
      };
    case 'SET_PARTNER_ID':
      return {
        ...state,

        activeQuote:
          state.activeQuote && action.payload
            ? {
                ...state.activeQuote,
                PartnerTrackingId: action.payload ?? null,
              }
            : null,
        campaign: {
          ...state.campaign,
          partnerTrackingId: action.payload ?? null,
        },
      };
    case 'SET_AFFILIATE_ID':
      return {
        ...state,
        activeQuote:
          state.activeQuote && action.payload
            ? {
                ...state.activeQuote,
                AffiliateId: action.payload ?? null,
              }
            : null,
        campaign: {
          ...state.campaign,
          affiliateId: action.payload ?? null,
        },
      };
    case 'SET_SSKEY':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          sskey: action.payload ?? null,
        },
      };
    case 'SET_UTM_SOURCE':
      return {
        ...state,
        campaign: {
          ...state.campaign,
          utmSource: action.payload ?? null,
        },
      };
    case 'SET_DATALAYER_CONTENT_TYPE':
      return {
        ...state,
        dataLayerContentType: action.payload,
      };
    case 'SET_POLICES':
      return {
        ...state,
        policyIds: [...action.payload],
      };
    case 'SET_EXTRA_DATA':
      return {
        ...state,
        extraData: action.payload,
      };
    case 'SET_BRAND': {
      try {
        Sentry.setTag('agria_brand', action.payload.name);
      } catch (error) {
        // do nothing
      }
      return {
        ...state,
        brand: action.payload,

        activeQuote: {
          ...(state.activeQuote ?? {}),
          BrandId: action.payload.brandId,
        },
      };
    }
    case 'CANCEL_OUTSTANDING_QUOTE_REQUESTS': {
      // remove and property without quoteIds and if quoteIds is less the count set the count to the length of quoteIds
      const requestedQuotes = { ...state.requestedQuotes };
      Object.entries(requestedQuotes).forEach(([key, value]) => {
        if (value.count > 0 && value.count !== value?.quoteIds?.length) {
          value.count = value?.quoteIds?.length ?? 0;
        } else if (value.count === 0) {
          delete requestedQuotes[key];
        }
      });
      return {
        ...state,
      };
    }
    case 'SET_QUOTE_REQUESTS': {
      const activeSpecies = action.payload
        ? Object.keys(action.payload)[0]
        : null;

      // Check if another pet in requestedQuotes is of the same species (objects key)
      const nextQuoteSpecies = action.payload
        ? Object.entries(action.payload).find(
            ([_, value]) =>
              value.count >= 1 && value.count !== (value?.quoteIds?.length ?? 0)
          )
        : undefined;

      // if new quote request does  contain any quoteIds then we need to keep the basket if not remove it

      let BasketId = state.basketId ?? state.activeQuote?.BasketId;
      let { basket, basketId } = state;

      if (
        action.payload &&
        !Object.values(action.payload).some(
          (item) => item?.quoteIds && item?.quoteIds?.length > 0
        )
      ) {
        basket = null;
        BasketId = undefined;
        basketId = null;
      }

      return {
        ...state,
        basketId,
        basket,
        requestedQuotes: action.payload ?? null,
        affiliatePolicyRequests: [],
        activeQuote: {
          BrandId: state.brand?.brandId ?? null,
          Customer: state.activeQuote?.Customer ?? null,
          BasketId,
          Pet: {
            SpeciesId: nextQuoteSpecies?.[0] ?? activeSpecies,
          },
        },
      };
    }
    case 'SET_ACTIVE_QUOTE': {
      // if this Quote.Id is not in the requestedQuotes then we need to add it

      const speciesId = action.payload?.Pet?.SpeciesId;
      const currentRequestedQuotes = state?.requestedQuotes ?? {};

      const currentRequest = currentRequestedQuotes?.[speciesId!];
      const currentQuoteIds = speciesId
        ? currentRequestedQuotes?.[speciesId]?.quoteIds
        : [];

      const requestedQuotes =
        action?.payload?.Id &&
        speciesId &&
        !currentQuoteIds?.includes(action.payload.Id)
          ? {
              ...state.requestedQuotes,
              [speciesId]: {
                quoteIds: [...(currentQuoteIds || []), action.payload.Id],
                count: currentRequest?.count ?? 1,
              },
            }
          : state.requestedQuotes;

      console.debug(action.payload);

      try {
        Sentry.setContext('activeQuote', {
          SpeciesId: speciesId,
          BreedId: action.payload?.Pet?.BreedId,
          Id: action.payload?.Id,
          AffiliateId: action?.payload?.AffiliateId,
          PartnerTrackingId: action?.payload?.PartnerTrackingId,
          BasketId: action?.payload?.BasketId,
          BrandId: action?.payload?.BrandId,
          CampaignId: action?.payload?.CampaignId,
          ProductId: action?.payload?.ProductId,
          QuoteReference: action?.payload?.QuoteReference,
          TotalBasketPrice: action?.payload?.TotalBasketPrice,
          SelectedFixedExcessId: action?.payload?.SelectedFixedExcessId,
          CustomerId: action?.payload?.Customer?.Id,
          PetId: action?.payload?.Pet?.Id,
          PetName: action?.payload?.Pet?.Name,
          IsRenewalQuote: action?.payload?.IsRenewalQuote,
        });

        if (action?.payload?.Customer?.Id) {
          Sentry.setUser({
            id: action.payload?.Customer.Id,
          });
        }
      } finally {
        // NOOP
      }

      const returnState = {
        ...state,
        activeQuote: action.payload ?? null,
        requestedQuotes,
      };
      // fix up home cat
      if (returnState.activeQuote?.SelectedCoverOptionId) {
        if (
          returnState.activeQuote?.Pet?.SubSpeciesId ===
          subSpeciesIds.find((species) => species.name === 'home cat')?.id
        ) {
          returnState.extraData = {
            ...returnState.extraData,
            Homecat: true,
          };
        } else if (
          returnState.activeQuote?.Pet?.SpeciesId ===
            'c2597178-071f-4940-825d-4d7351b5ffa4' &&
          returnState.activeQuote.SelectedCoverOptionId
        ) {
          // if activeQuote.SelectedCoverOptionId is not set then set Homecat to false
          returnState.extraData = {
            ...returnState.extraData,
            Homecat: false,
          };
        } else {
          delete returnState?.extraData?.Homecat;
        }
      }

      if (action.payload?.BasketId) {
        returnState.basketId = action.payload?.BasketId;
      }
      return returnState;
    }

    case 'SET_ACTIVE_QUOTE_BY_ID': {
      const activeQuote = state.basket?.Quotes.find(
        (q) => q.Id === action.payload
      );
      return {
        ...state,
        activeQuote,
      };
    }
    case 'SET_ACTIVE_PET': {
      const extraData = {
        ...state.extraData,
      };

      if (typeof action.payload?.Homecat === 'boolean') {
        extraData.Homecat = action.payload?.Homecat;
      } else {
        delete extraData.Homecat;
      }

      const updatePet = {
        ...state.activeQuote?.Pet,
        ...action.payload,
      };

      if ('SubSpeciesId' in action.payload) {
        updatePet.SubSpeciesId = action.payload.SubSpeciesId;
      }

      const returnData = {
        ...state,
        extraData,
        activeQuote: {
          ...state.activeQuote,
          Pet: updatePet,
        },
      };

      return returnData;
    }
    case 'SET_ACTIVE_CUSTOMER':
      return {
        ...state,
        activeQuote: {
          ...state.activeQuote,
          Customer: {
            ...state.activeQuote?.Customer,
            ...action.payload,
          },
        },
      };
    case 'SET_ADDITIONAL_POLICY_HOLDER':
      return {
        ...state,
        additionalPolicyHolder: {
          ...state.additionalPolicyHolder,
          ...(action.payload ?? null),
        },
      };
    case 'REMOVE_ADDITIONAL_POLICY_HOLDER':
      return {
        ...state,
        additionalPolicyHolder: null,
      };

    case 'SET_BASKET': {
      if (!action.payload) {
        return state;
      }

      // const currentQuote = action.payload.Quotes.find((quote) => quote.QuoteReference === '');
      // if we have an active quote already we should return to that one

      // const currentQuote = state.activeQuote?.Id
      //   ? action.payload?.Quotes.find((quote) => {
      //       console.debug(quote.Id, state.activeQuote?.Id);
      //       return quote.Id === state.activeQuote?.Id;
      //     })
      //   : action.payload?.Quotes?.[0];

      let currentQuote = action.payload?.Quotes.find(
        (quote) => quote.Id === state.activeQuote?.Id
      ) ?? { ...state.activeQuote };

      if (!state.activeQuote?.Id) {
        console.log('SETIING CURRENT QUOTE TO FIRST QUOTE IN BASKET');
        currentQuote = action.payload?.Quotes?.[0];
      }

      // loop basket quotes.
      // for each put the id into the correct species
      // if no species then add it

      const requestedQuotes = action.payload?.Quotes.reduce<RequestedQuotes>(
        (prev, curr) => {
          const updated: RequestedQuotes = {
            ...prev,
          };
          const speciesId = curr.Pet.SpeciesId;

          const checkExists =
            speciesId &&
            updated?.[speciesId]?.quoteIds &&
            Array.isArray(updated[speciesId].quoteIds) &&
            !updated[speciesId].quoteIds?.includes(curr.Id);

          // if there is already a speciesId in the requested quotes
          if (checkExists) {
            updated[speciesId]?.quoteIds?.push(curr.Id);
            updated[speciesId].count += 1;

            return updated;
          }
          // if we have a speciesId but no quoteIds
          if (speciesId) {
            updated[speciesId] = {
              quoteIds: [curr.Id],
              count: 1,
            };
            return updated;
          }
          // all other cases
          return updated;
        },
        { ...state?.requestedQuotes }
      );

      try {
        Sentry.setContext('basket', {
          Id: action.payload?.Id,
          reference: action.payload?.Reference,
          promoCode: action.payload?.PromoCode,
          existingUserWithEmail: action.payload?.ExistingUserWithEmail,
          policieCount: action.payload?.Policies?.length || 0,
          quoteCount: action.payload?.Quotes?.length || 0,
        });
      } finally {
      }

      return {
        ...state,
        basket: action.payload,
        basketId: action.payload.Id,
        activeQuote: currentQuote ?? state.activeQuote,
        requestedQuotes,
      };
    }

    case 'SET_EDIT_MODE': {
      return {
        ...state,
        editMode: action.payload ?? null,
      };
    }

    default:
      return state;
  }
};

export const QuoteContext = createContext<QuoteContextStateReturn>(
  initialState as QuoteContextStateReturn
);

export const QuoteContextProvider = ({
  children,
  location,
}: QuoteContextProviderProps) => {
  const qs = getQueryString<QueryContextQueryStrings>(location?.search);
  const brand = useBrand();
  const [state, dispatch] = useReducer<
    React.Reducer<QuoteContextState, Action>
  >(reducer, initialState);
  const { subSpecies } = useSiteMetadata();

  const getDataLayerContentType = useGetDataLayerContentType();

  const { campaignId, partnerTrackingId } = useCampaignId();

  // Hook used too sync context to session storage, to allow refreshes of the page etc
  useSyncContextToSessionStorage('QuoteContext', { state, dispatch });

  // Check for default /override promo code
  const defaultPromoCode = useDefaultPromoCode();

  // Hook to get Basket data
  const {
    data: basketData,
    isLoading: basketLoading,
    fetchBasket: fetchBasketFnc,
    refetch: refetchBasket,
    error: basketError,
  } = useGetBasket();

  const { mutateAsync: getAffiliate } = useAffiliateLookup();

  const fetchBasket = useCallback(() => {
    const id = state.basketId || state.activeQuote?.BasketId;
    console.debug('fetchBasket', id);

    if (id) {
      console.debug('fetching basket', id);
      fetchBasketFnc(id);
    } else {
      console.debug('no basket id');
    }
  }, [fetchBasketFnc, state.activeQuote?.BasketId, state.basketId]);

  // Logic to get and save Brand
  useEffect(() => {
    if (brand && !state.brand?.brandId) {
      dispatch({
        type: 'SET_BRAND',
        payload: brand,
      });
    }
  }, [brand, state.brand?.brandId]);

  // Logic to set Promo Code
  useEffect(() => {
    if (qs?.promocode || qs?.pc) {
      dispatch({
        type: 'SET_PROMO_CODE',
        payload: qs.promocode || qs.pc || '',
      });
    }
  }, [qs?.promocode, qs?.pc]);

  // Logic to set Partner Tracking ID
  useEffect(() => {
    if (qs?.partnertrackingid || qs?.partnerTrackingDd) {
      dispatch({
        type: 'SET_PARTNER_ID',
        payload: qs.partnertrackingid || qs.partnerTrackingDd || '',
      });
    }
  }, [qs?.partnerTrackingDd, qs?.partnertrackingid]);

  // Logic to get and save campaign ID
  // Removed for now while age limit is removed via a campaignId
  // useEffect(() => {
  //   if (qs?.camid) {
  //     dispatch({
  //       type: 'SET_CAMPAIGN_ID',
  //       payload: qs.camid,
  //     });
  //   }
  // }, [qs?.camid]);
  useEffect(() => {
    if (campaignId) {
      dispatch({
        type: 'SET_CAMPAIGN_ID',
        payload: campaignId,
      });
    }
  }, [campaignId]);

  useEffect(() => {
    if (partnerTrackingId) {
      dispatch({
        type: 'SET_PARTNER_ID',
        payload: partnerTrackingId,
      });
    }
  }, [partnerTrackingId]);

  useEffect(() => {
    if (qs?.basketId) {
      dispatch({
        type: 'SET_BASKET_ID',
        payload: qs.basketId,
      });
    }
  }, [qs?.basketId]);

  // Logic to get and save Affiliate ID
  useEffect(() => {
    if (qs?.affid) {
      // sometimes affid is a GUID and sometimes it is an AgriaId
      if (isGUID(qs.affid)) {
        dispatch({
          type: 'SET_AFFILIATE_ID',
          payload: qs.affid,
        });
      } else {
        getAffiliate({ agriaId: qs.affid })
          .then((response) => {
            dispatch({
              type: 'SET_AFFILIATE_ID',
              payload: response.Id,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [getAffiliate, qs?.affid]);

  // Logic to get and save SS KEY - for Optimise

  useEffect(() => {
    if (qs?.sskey) {
      // Future self this is also in packages/themes/brochureware/gatsby-browser.tsx
      setCookie('sskey', qs.sskey, 30);
      dispatch({
        type: 'SET_SSKEY',
        payload: qs.sskey,
      });
    } else if (getCookie('sskey')) {
      dispatch({
        type: 'SET_SSKEY',
        payload: getCookie('sskey'),
      });
    }
  }, [qs?.sskey]);

  // Logic to get and save UTM source - for Optimise

  useEffect(() => {
    if (qs?.utm_source) {
      dispatch({
        type: 'SET_UTM_SOURCE',
        payload: qs.utm_source,
      });
    }
  }, [qs?.utm_source]);

  // Logic to get Basket via Guid
  useEffect(() => {
    if (qs?.quoterefGuid) {
      fetchBasketFnc(qs?.quoterefGuid);
    }
  }, [fetchBasketFnc, qs?.quoterefGuid]);

  // logic to pickup grabing the basket and set it to state
  useEffect(() => {
    if (basketData) {
      console.log('basketData update');
      dispatch({
        type: 'SET_BASKET',
        payload: basketData,
      });
      try {
        Sentry.setContext('basket', {
          id: basketData?.Id,
          numberOfPolicies: basketData?.Policies?.length,
          numberOfQuotes: basketData?.Quotes?.length,
        });
      } catch (error) {
        // do nothing
      }
    }
  }, [basketData]);

  // Check for a stale basket
  // if there is a bakset inthe store but no basket data from the api
  // useEffect(() => {
  //   if (!basketLoading && !basketData && state.basket && state.basketId) {
  //     fetchBasket();
  //   }
  // }, [basketData, basketLoading, fetchBasket, state.basket, state.basketId]);

  // how may quotes have been requested
  const totalQuoteRequests: number = state?.requestedQuotes
    ? Object.entries(state.requestedQuotes).reduce(
        (prev, [_, quoteDetails]) => prev + quoteDetails.count ?? 0,
        0
      )
    : 0;

  // how many quotes have been completed)
  const totalCompletedQuoteRequests = state?.requestedQuotes
    ? Object.entries(state.requestedQuotes).reduce(
        (prev, [_, quoteDetails]) =>
          prev + (quoteDetails?.quoteIds?.length ?? 0),
        0
      )
    : 0;

  // Set our dataLayerContentType and make available in state
  useEffect(() => {
    const datalayerContentType = getDataLayerContentType(
      state.requestedQuotes as RequestedQuotes,
      subSpecies
    );

    dispatch({
      type: 'SET_DATALAYER_CONTENT_TYPE',
      payload: datalayerContentType,
    });
  }, [getDataLayerContentType, state.requestedQuotes]);

  const [allActiveSpeciesCountMap, setAllActiveSpeciesCountMap] = useState<{
    [key: string]: number;
  }>({});

  const returnValue: QuoteContextStateReturn = useMemo(() => {
    // activeSpecies  ID
    const activeSpecies: string | null =
      state?.activeQuote?.Pet?.SpeciesId || null;

    // activeSpecies quoteIds
    const activeSpeciesQuoteIds =
      activeSpecies && state.requestedQuotes
        ? state.requestedQuotes?.[activeSpecies]?.quoteIds
        : [];

    //  Get current species count. e.g if Active quote is for a cat, how many cats have been completed so far + 1
    // if the active species has an id then where is it in the activeSpeciesQuoteIds
    let activeSpeciesCount = 1;
    const activeSpeciesTotalCount =
      activeSpecies && state.requestedQuotes?.[activeSpecies]
        ? state.requestedQuotes[activeSpecies]?.count
        : 1;

    if (
      activeSpecies &&
      activeSpeciesQuoteIds?.length &&
      state.activeQuote?.Id
    ) {
      activeSpeciesCount =
        activeSpeciesQuoteIds.indexOf(state.activeQuote.Id) + 1;
    } else {
      activeSpeciesCount = activeSpeciesQuoteIds?.length
        ? activeSpeciesQuoteIds.length + 1
        : 1;
    }

    // Get total count of active animals across all species
    const allActiveSpeciesTotalCount = state?.requestedQuotes
      ? Object.entries(state.requestedQuotes).reduce(
          (prev, [_, quoteDetails]) => prev + (quoteDetails.count ?? 0),
          0
        )
      : 0;

    // Calculate allActiveSpeciesCount as position of active animal across all species
    let allActiveSpeciesCount = 0;
    const activeQuoteId = state.activeQuote?.Id;

    // If editing an animal's details
    if (activeQuoteId && allActiveSpeciesCountMap[activeQuoteId]) {
      allActiveSpeciesCount = allActiveSpeciesCountMap[activeQuoteId];
    } else {
      // determine allActiveSpeciesCount for all animals
      const allSpeciesQuoteIds = state?.requestedQuotes
        ? Object.values(state.requestedQuotes).flatMap(
            (item) => item.quoteIds || []
          )
        : [];
      if (state.activeQuote?.Id) {
        allActiveSpeciesCount =
          allSpeciesQuoteIds.indexOf(state.activeQuote.Id) + 1;
      } else {
        allActiveSpeciesCount = allSpeciesQuoteIds.length + 1;
      }

      // If adding a new animal's details, store the count in the map
      if (activeQuoteId) {
        setAllActiveSpeciesCountMap((prevMap) => ({
          ...prevMap,
          [activeQuoteId]: allActiveSpeciesCount,
        }));
      }
    }
    // promoCode applied to any Quote in the Basket
    const promoCode =
      state.promoCode ||
      defaultPromoCode ||
      state?.basket?.Quotes?.find((quote) => !!quote.PromoCode)?.PromoCode;
    return {
      ...state,
      activeSpecies,
      activeSpeciesCount,
      activeSpeciesTotalCount,
      allActiveSpeciesTotalCount,
      allActiveSpeciesCount,
      dispatch,
      fetchBasket,
      basketLoading,
      basketError,
      totalQuoteRequests,
      totalCompletedQuoteRequests,
      policyIds: state.policyIds,
      refetchBasket,
      hasBasket: !!basketData,
      promoCode,
    };
  }, [
    state,
    allActiveSpeciesCountMap,
    fetchBasket,
    basketLoading,
    basketError,
    totalQuoteRequests,
    totalCompletedQuoteRequests,
    refetchBasket,
    basketData,
    defaultPromoCode,
  ]);
  return (
    <QuoteContext.Provider value={returnValue}>
      {children}
    </QuoteContext.Provider>
  );
};

QuoteContext.displayName = 'QuoteContext';
