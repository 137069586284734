import React, { useContext } from 'react';
import Stack from '@agria/theme/src/components/stack/Stack';
import { Typography } from '@agria/theme/src/components/typography/Typography';
import Surface from '@agria/theme/src/components/surface/Surface';
import { Button } from '@agria/theme/src/components/button';
import { isBrowser } from '@agria/utils/src/isBrowser';
import { Divider } from '@agria/theme/src/components/divider/Divider';
import ExpanderContent from '@agria/theme/src/components/expanderContent/ExpanderContent';
import { isProd } from '@agria/utils';
import { useSiteMetadata } from '@agria/theme/src/hooks/useSiteMetadata';
import styled from '@emotion/styled';
import { getApiBaseUrl } from '@agria/paws/src/client.mjs';
import { useIsAlive } from '@agria/paws/src/hooks/useIsAlive';
import { ActiveStepContext } from '../context/ActiveStepContext';
import { QuoteContext } from '../context/QuoteContext';

const StyledStack = styled(Stack)`
  max-width: 100vw;
`;
const StyledPre = styled.pre`
  max-width: 100vw;
  overflow-x: auto;
`;
export function StateDebugger() {
  const [show, setShow] = React.useState(false);
  const { setActiveStep, ...rest } = useContext(ActiveStepContext);
  const { dispatch, requestedQuotes, activeSpeciesCount, ...quote } =
    useContext(QuoteContext);
  const siteMetaData = useSiteMetadata();

  const { data: isAliveData, isPending: isAliveLoading } = useIsAlive();

  if (isProd) {
    return null;
  }
  const storedValue = isBrowser
    ? window.sessionStorage.getItem('Agria@QuoteContext')
    : '';

  if (isProd) {
    return null;
  }

  const isAlive = isAliveData ? '✅' : '❌';
  return (
    <Surface>
      <StyledStack>
        <Button onClick={() => setShow(!show)}>Toggle Debug Info</Button>
        {show && (
          <Stack>
            <Typography variant="headingMedium">
              Agria@QuoteContext on Step {rest?.activeStep}
            </Typography>
            <StyledPre>
              <code>{storedValue}</code>
            </StyledPre>

            <Button
              variant="primaryOutline"
              arrowRight
              url={`mailto:development@freshegg.com?subject=Agria%20Bug%20Report&body=Active%20Step%3A${
                rest?.activeStep
              }%0D%0A%0D%0A${encodeURIComponent(storedValue)}`}
            >
              Email Report to Fresh Egg
            </Button>
            <Divider />
            <ExpanderContent
              expanderTitle="More details"
              expanderContent={
                <>
                  <Stack>
                    <Typography variant="headingMedium">
                      requestedQuotes
                    </Typography>
                    <StyledPre>
                      <code>
                        {requestedQuotes
                          ? JSON.stringify(requestedQuotes, null, 2)
                          : ''}
                      </code>
                      <br />
                      <code>Active Species Count: {activeSpeciesCount}</code>
                      <br />
                      Is API up? {isAliveLoading ? 'LOADING' : isAlive}
                      <br />
                      <code>API URL: {getApiBaseUrl()}</code>
                    </StyledPre>
                  </Stack>
                  <Stack>
                    <Typography variant="headingMedium">
                      Site Meta Data
                    </Typography>
                    <StyledPre>
                      <code>{JSON.stringify(siteMetaData, null, 2)}</code>
                    </StyledPre>
                  </Stack>
                  <Stack>
                    <Typography variant="headingMedium">
                      QuoteContext
                    </Typography>
                    <StyledPre>
                      <code>{JSON.stringify(quote, null, 2)}</code>
                    </StyledPre>
                  </Stack>
                </>
              }
            />
          </Stack>
        )}
      </StyledStack>
    </Surface>
  );
}
