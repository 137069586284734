import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { GatsbySSR } from 'gatsby';
import GlobalStyles from './src/styles/GlobalStyles';
import { HeaderProvider } from './src/context/HeaderContext';
import theme from './src/styles/tokens';
import { ScriptsBefore } from './src/components/ScriptsBefore';
import { ExperimentsContextProvider } from './src/context/ExperimentsContext';
import { ExperimentsWrapper } from './src/components/ExperimentsWrapper';
import { UiStateContextProvider } from './src/context/UiStateContext';

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({
  element,
  props,
}) => (
  <ExperimentsContextProvider>
    <ExperimentsWrapper data={props.data}>{element}</ExperimentsWrapper>
  </ExperimentsContextProvider>
);

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => (
  <UiStateContextProvider>
    <ThemeProvider theme={theme}>
      <HeaderProvider>
        <ScriptsBefore />
        <GlobalStyles />
        {element}
      </HeaderProvider>
    </ThemeProvider>
  </UiStateContextProvider>
);
