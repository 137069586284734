import React, { createContext, useMemo, useState } from 'react';

export type Blades = string | 'storybookDemo' | 'map' | 'digitalBanner' | null;

type BladeWidths = 'narrow' | 'wide';
type ActiveBladeTriggerElType =
  | React.MutableRefObject<HTMLButtonElement>
  | undefined
  | null;
type ActiveBladeActionType = undefined | (() => void);

interface UiStateContextProps {
  activeBlade: Blades;
  setActiveBlade: React.Dispatch<React.SetStateAction<Blades>>;
  activeBladeId: string | undefined;
  setActiveBladeId: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeBladeTriggerEl: ActiveBladeTriggerElType;
  setActiveBladeTriggerEl: React.Dispatch<
    React.SetStateAction<ActiveBladeTriggerElType | undefined>
  >;
  activeBladeAction: ActiveBladeActionType;
  setActiveBladeAction: React.Dispatch<
    React.SetStateAction<ActiveBladeActionType>
  >;
  activeBladeActionLabel: string | undefined;
  setActiveBladeActionLabel: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  activeBladeWidth: BladeWidths | undefined;
  setActiveBladeWidth: React.Dispatch<
    React.SetStateAction<BladeWidths | undefined>
  >;
  activeBladeAnimate: boolean | undefined;
  setActiveBladeAnimate: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  heroOutOfView: boolean | undefined;
  setHeroOutOfView: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const UiStateContext = createContext({} as UiStateContextProps);
UiStateContext.displayName = 'UiStateContext';

export const UiStateContextProvider = ({ children }) => {
  const [activeBlade, setActiveBlade] = useState<Blades>(null);
  const [activeBladeId, setActiveBladeId] = useState<string | undefined>(
    undefined
  );
  const [activeBladeTriggerEl, setActiveBladeTriggerEl] =
    useState<ActiveBladeTriggerElType>(undefined);
  const [activeBladeAction, setActiveBladeAction] =
    useState<ActiveBladeActionType>(undefined);
  const [activeBladeActionLabel, setActiveBladeActionLabel] = useState<
    string | undefined
  >(undefined);
  const [activeBladeWidth, setActiveBladeWidth] = useState<
    BladeWidths | undefined
  >(undefined);
  const [activeBladeAnimate, setActiveBladeAnimate] = useState<
    boolean | undefined
  >(true);
  const [heroOutOfView, setHeroOutOfView] = useState<boolean | undefined>(true);

  const contextValues: UiStateContextProps = useMemo(
    () => ({
      activeBlade,
      setActiveBlade,
      activeBladeId,
      setActiveBladeId,
      activeBladeTriggerEl,
      setActiveBladeTriggerEl,
      activeBladeAction,
      setActiveBladeAction,
      activeBladeWidth,
      setActiveBladeWidth,
      activeBladeActionLabel,
      setActiveBladeActionLabel,
      activeBladeAnimate,
      setActiveBladeAnimate,
      heroOutOfView,
      setHeroOutOfView,
    }),
    [
      activeBlade,
      setActiveBlade,
      activeBladeId,
      setActiveBladeId,
      activeBladeTriggerEl,
      setActiveBladeTriggerEl,
      activeBladeAction,
      setActiveBladeAction,
      activeBladeWidth,
      setActiveBladeWidth,
      activeBladeActionLabel,
      setActiveBladeActionLabel,
      activeBladeAnimate,
      setActiveBladeAnimate,
      heroOutOfView,
      setHeroOutOfView,
    ]
  );

  return (
    <UiStateContext.Provider value={contextValues}>
      {children}
    </UiStateContext.Provider>
  );
};
