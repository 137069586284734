import { useCallback } from 'react';
import { speciesReference } from '../const/speciesReference';
import { RequestedQuotes } from '../context/QuoteContext';

const useGetDataLayerContentType = () => {
  const getDataLayerContentType = useCallback(
    (requestedQuotes: RequestedQuotes, subSpecies?: string) => {
      if (!requestedQuotes || typeof requestedQuotes !== 'object') {
        return 'No requested quotes available.';
      }

      let totalSpeciesCount = 0;
      let quoteString = '';

      // Check if subSpecies is provided and true
      if (subSpecies) {
        Object.entries(requestedQuotes).forEach(([, speciesData]) => {
          const speciesCount = speciesData.count;
          totalSpeciesCount += speciesCount;
        });

        // Format the quote string to include subSpecies name and total count
        quoteString = `${totalSpeciesCount} ${subSpecies}${
          totalSpeciesCount > 1 ? 's' : ''
        }`;
      } else {
        Object.entries(requestedQuotes).forEach(([speciesId, speciesData]) => {
          const speciesCount = speciesData.count;
          const speciesName = speciesReference[speciesId]?.displayName;

          if (speciesName) {
            totalSpeciesCount += speciesCount;
            quoteString += `${speciesCount} ${speciesName}${
              speciesCount > 1 ? 's' : ''
            }, `;
          }
        });

        if (totalSpeciesCount > 0) {
          quoteString =
            totalSpeciesCount > 1
              ? `Multipet - ${quoteString.slice(0, -2)}`
              : `Single pet - ${quoteString.slice(0, -2)}`;
        } else {
          quoteString = 'No requested quotes available.';
        }
      }

      return quoteString;
    },
    []
  );

  return getDataLayerContentType;
};

export default useGetDataLayerContentType;
