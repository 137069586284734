import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Pdf: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M6.51 26V6h10.586l3.414 3.414V12h2V8.586L17.924 4H4.51v24h18.002l-.009-4.002-2 .004.005 1.998H6.51Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.862 14.02v7.969h1.62V19.73h1.173c1.528 0 2.588-1.128 2.588-2.815 0-1.733-1.083-2.896-2.656-2.896H9.862Zm1.585 4.252v-2.77h1.208c.605 0 1.038.547 1.038 1.368 0 .844-.433 1.402-1.038 1.402h-1.208ZM16.224 22v-7.98h2.553c2.018 0 3.363 1.585 3.363 3.967 0 2.406-1.345 4.013-3.363 4.013h-2.553Zm1.607-1.539v-4.902h.923c1.072 0 1.779.98 1.779 2.44 0 1.482-.707 2.462-1.779 2.462h-.923Zm9.37-1.3H24.9V22h-1.607v-7.98h4.195v1.493h-2.588v2.155h2.303v1.493Z"
      clipRule="evenodd"
    />
  </Icon>
);
