import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: MJS workaround to allow in JS file
import { service } from '../client.mjs';

import { Affiliate, ApiResponse } from '../types';
import { dataOrError } from '../utils/dataOrError';

export interface AffiliateLookupPayload {
  agriaId?: string;
  email?: string;
  postcode?: string;
}

const getAffiliateLookup = async ({
  agriaId,
  email,
  postcode,
}: AffiliateLookupPayload) => {
  const { data } = await service.get<ApiResponse<Affiliate>>(
    `/Affiliate/Lookup?agriaId=${agriaId ?? ''}&email=${email ?? ''}&postcode=${
      postcode ?? ''
    }`
  );
  return dataOrError<Affiliate>(data);
};

export function useAffiliateLookup() {
  return useMutation({
    mutationFn: getAffiliateLookup
  });
}
